import deepClone from "@/utils/deepClone";
import GC from "@grapecity/spread-sheets";
import { Header, RowProps, BudgetReportProps } from "./types";
// router的类型
import type { Router } from "vue-router";
export interface QueryParams {
  brand: string[];
  bu: string[];
  check: boolean;
  kufri: string;
  make: string;
  reportView: string;
  rtOrWs: string;
  yearQm: string;
  submitStatus: string[];
  activeStatus: string;
  planningVersion: number | null;
  year: string;
  qm: string[];
}
//分组列在第一次没有数据初始化后会导致后续更新报错，所以分组列必须要在有数据才可以分组
export function initOutlineColumn(sheet: GC.Spread.Sheets.Worksheet) {
  sheet.outlineColumn.options({
    columnIndex: 0,
    showImage: true,
    showCheckBox: false,
    expandIndicator: require("@/assets/images/increaseIndicator.png"),
    collapseIndicator: require("@/assets/images/decreaseIndicator.png"),
    maxLevel: 2,
  });
  sheet.showRowOutline(false);
  sheet.outlineColumn.refresh();
}

// 设置表格的头部，可以不占用单元格
export const setHeader = (
  sheet: GC.Spread.Sheets.Worksheet,
  header: Header[][]
) => {
  //挂起
  sheet.suspendPaint();
  //设置header行数为2行
  sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);
  const row = sheet.getRange(
    1,
    -1,
    1,
    -1,
    GC.Spread.Sheets.SheetArea.colHeader
  );
  row.wordWrap(true);
  //set header 设置头的样式
  for (let row = 0; row < header.length; row++) {
    for (let cell = 0; cell < header[row].length; cell++) {
      sheet.setValue(
        row,
        cell,
        header[row][cell].name,
        GC.Spread.Sheets.SheetArea.colHeader
      );
      if ([1].includes(cell)) {
        sheet
          .getCell(row, cell, GC.Spread.Sheets.SheetArea.colHeader)
          .hAlign(GC.Spread.Sheets.HorizontalAlign.left);
      }
    }
    sheet.setRowHeight(row, 30, GC.Spread.Sheets.SheetArea.colHeader);
  }
  //合并行
  // sheet.addSpan(0, 0, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);
  // 筛选
  // const range = new GC.Spread.Sheets.Range(-1, 0, -1, 2);
  // const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
  // sheet.rowFilter(rowFilter);
  sheet.addSpan(0, 0, 1, 2, GC.Spread.Sheets.SheetArea.colHeader);
  sheet.addSpan(0, 2, 1, 4, GC.Spread.Sheets.SheetArea.colHeader);
  sheet.addSpan(0, 6, 1, 4, GC.Spread.Sheets.SheetArea.colHeader);
  // // 必须加上这两个才能折叠展开
  // sheet.options.protectionOptions.allowOutlineRows = true
  // sheet.options.protectionOptions.allowOutlineColumns = true
  // 自动合并
  // const ranges = new GC.Spread.Sheets.Range(-1, -1, -1, -1);
  // sheet.autoMerge(ranges, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.row, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.colHeader);
  // sheet.autoMerge(ranges, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.column, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.rowHeader);2
  //重绘
  sheet.resumePaint();
};

export const setCollapsed = (
  sheet: GC.Spread.Sheets.Worksheet,
  data: RowProps[],
  isCollapsed: boolean,
  level = 1
) => {
  sheet.suspendPaint();
  //强制折叠所有二级
  for (let row = 0; row < data.length; row++) {
    if (data[row].level === level) {
      sheet.rowOutlines.setCollapsed(row, isCollapsed);
    }
  }
  //根据type来确认是否显示图标
  /* if (type === 'TypeClass') {
        sheet.outlineColumn.options().showIndicator = true;
        sheet.outlineColumn.options().expandIndicator = require('@/assets/images/increaseIndicator.png');
        sheet.outlineColumn.options().collapseIndicator = require('@/assets/images/decreaseIndicator.png');
    } else {
        sheet.outlineColumn.options().showIndicator = false;
        sheet.outlineColumn.options().expandIndicator = null;
        sheet.outlineColumn.options().collapseIndicator = null;
    }*/

  sheet.outlineColumn.refresh();
  sheet.resumePaint();
};

export const bindDataTable = (
  sheet: GC.Spread.Sheets.Worksheet,
  data: RowProps[],
  router: Router,
  queryParams: QueryParams
): void => {
  //挂起
  sheet.suspendPaint();

  //设置数据源
  sheet.setDataSource(data);

  /* //setDataSource true 重置表格样式也会重置,这里重新设置默认样式
    sheet.options.rowHeaderVisible = false;
    //默认对其方式
    const defaultStyle = new GC.Spread.Sheets.Style();
    //默认对其方式
    defaultStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
    defaultStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    defaultStyle.locked = true;
    sheet.setDefaultStyle(defaultStyle);
*/

  //绑定列
  sheet.bindColumn(0, "typeClass");
  sheet.bindColumn(1, "model");
  sheet.bindColumn(2, "mavgSiPercent");
  sheet.bindColumn(3, "mguideline");
  sheet.bindColumn(4, "mdeviation");
  sheet.bindColumn(5, "mdeviationAbsolutAmount");
  sheet.bindColumn(6, "yavgSiPercent");
  sheet.bindColumn(7, "yguideline");
  sheet.bindColumn(8, "ydeviation");
  sheet.bindColumn(9, "ydeviationAbsolutAmount");

  //设置列数
  sheet.setColumnCount(10, GC.Spread.Sheets.SheetArea.viewport);

  //设置没列的宽度
  sheet.setColumnWidth(0, "2*");
  sheet.setColumnWidth(1, "4*");
  sheet.setColumnWidth(2, "2*");
  sheet.setColumnWidth(3, "2*");
  sheet.setColumnWidth(4, "2*");
  sheet.setColumnWidth(5, "2*");
  sheet.setColumnWidth(6, "2*");
  sheet.setColumnWidth(7, "2*");
  sheet.setColumnWidth(8, "2*");
  sheet.setColumnWidth(9, "2*");

  //数据格式化
  for (let row = 0; row < data.length; row++) {
    const level = data[row].level;
    sheet.getCell(row, 0).textIndent(level);
    sheet.getCell(row, 1).wordWrap(true);
    sheet.getCell(row, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
    sheet.setRowHeight(row, 30, GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 2, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 3, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 4, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 5, "#,##0", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 6, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 7, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 8, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 9, "#,##0", GC.Spread.Sheets.SheetArea.viewport);
    // 金额右对齐
    sheet.getCell(row, 5).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    sheet.getCell(row, 9).hAlign(GC.Spread.Sheets.HorizontalAlign.right);

    // 自定义边框样式 区分MTD和YTD
    sheet
      .getRange(-1, 5, -1, 1, GC.Spread.Sheets.SheetArea.viewport)
      .borderRight(
        new GC.Spread.Sheets.LineBorder("#333", GC.Spread.Sheets.LineStyle.thin)
      );
    sheet
      .getRange(-1, 5, -1, 1, GC.Spread.Sheets.SheetArea.colHeader)
      .borderRight(
        new GC.Spread.Sheets.LineBorder("#333", GC.Spread.Sheets.LineStyle.thin)
      );
    sheet
      .getRange(-1, 1, -1, 1, GC.Spread.Sheets.SheetArea.viewport)
      .borderRight(
        new GC.Spread.Sheets.LineBorder("#333", GC.Spread.Sheets.LineStyle.thin)
      );
    sheet
      .getRange(-1, 1, -1, 1, GC.Spread.Sheets.SheetArea.colHeader)
      .borderRight(
        new GC.Spread.Sheets.LineBorder("#333", GC.Spread.Sheets.LineStyle.thin)
      );

    // 加粗typeclass
    sheet.getCell(row, 0).font("700 11pt Calibri");

    //deviation 为负数高亮显示
    if (Number(data[row].mdeviation) < 0) {
      sheet.getCell(row, 4).foreColor("red");
    }
    //deviation 为负数高亮显示
    if (Number(data[row].ydeviation) < 0) {
      sheet.getCell(row, 8).foreColor("red");
    }
    if (data[row].level !== 0 && sheet.getValue(row, 2) !== "-") {
      sheet.setHyperlink(row, 2, {
        url: "",
        command: function (sheet) {
          const routeUrl = router.resolve({
            path: "/SIBudgetReport",
            query: {
              model: data[row].model,
              typeClass: data[row].typeClass,
              year: queryParams.year,
              qm: queryParams.qm.join(","),
              bu: queryParams.bu[0],
              make: queryParams.make,
            },
          });
          window.open(routeUrl.href, "_blank");
        },
      });
      continue;
    }

    //根据数据中的visible字段来控制row显示
    if (!data[row].visible) {
      sheet.setRowVisible(row, false);
    } else {
      sheet.setRowVisible(row, true);
    }
    for (let i = 0; i < 10; i++) {
      if (data[row].level === 0) {
        sheet.getCell(row, i).backColor("#B0CEEA");
        sheet.getCell(row, i).font("700 11pt Calibri");
      }

      if (data[row].level === 1) {
        sheet.getCell(row, i).font("700 11pt Calibri");
      }
    }
  }
  //重绘
  sheet.resumePaint();
};

export const initSpread = (
  spread: GC.Spread.Sheets.Workbook,
  sheet: GC.Spread.Sheets.Worksheet,
  header: Header[][],
  data: RowProps[],
  router: Router,
  queryParams: QueryParams
): void => {
  spread.suspendPaint();

  //填充铺满整个canvas
  spread.options.scrollbarMaxAlign = true;
  spread.options.scrollByPixel = true;

  spread.options.tabNavigationVisible = false;
  spread.options.tabStripVisible = false;

  // 滚动条样式
  // spread.options.scrollbarAppearance = GC.Spread.Sheets.ScrollbarAppearance.mobile;

  // 设置header的高度
  // sheet.setRowHeight(0, 80, GC.Spread.Sheets.SheetArea.colHeader);

  // 设置多少行和列
  sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport);

  //禁止缩放
  spread.options.allowUserZoom = false;
  // 必须加上这两个才能折叠展开
  sheet.options.protectionOptions.allowOutlineRows = true;
  sheet.options.protectionOptions.allowOutlineColumns = true;

  // 设置整个表格不能插入， 删除行列
  // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
  // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
  sheet.options.protectionOptions.allowInsertRows = false;
  sheet.options.protectionOptions.allowInsertColumns = false;
  sheet.options.protectionOptions.allowDeleteRows = false;
  sheet.options.protectionOptions.allowDeleteColumns = false;

  //禁止拖动填充
  spread.options.allowUserDragFill = false;
  spread.options.allowUserDragDrop = false;

  // 设置隐藏头和列
  sheet.options.rowHeaderVisible = false;
  // 要设置允保护，不允许的才能生效
  sheet.options.isProtected = true;

  const defaultStyle = new GC.Spread.Sheets.Style();
  //默认对其方式
  defaultStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
  defaultStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
  defaultStyle.locked = true;
  sheet.setDefaultStyle(defaultStyle);

  // 绑定数据
  bindDataTable(sheet, data, router, queryParams);

  // 设置头部
  setHeader(sheet, header);

  spread.resumePaint();
};
// model 为空的时候 不显示这一行
const modelRowEmpty = (data: RowProps[]): RowProps[] => {
  if (!data.length) {
    return data;
  }
  const excludeKey = ["make", "model", "typeClass"];
  const keys = Object.keys(data[0]).filter((key) => !excludeKey.includes(key));
  return data.filter((item) =>
    keys.some((key) => {
      return Number(item[key]);
    })
  );
};

// 如果数据为0 转换成-
const transEmptyData = (data: RowProps[]) => {
  const excludeKey = ["make", "model", "typeClass", "level"];
  const keys = Object.keys(data[0]).filter((key) => !excludeKey.includes(key));
  data.forEach((item) => {
    keys.forEach((key) => {
      if (!Number(item[key])) {
        item[key] = "-";
      }
    });
  });
};

// 排序
const compare = (object1: RowProps, object2: RowProps, attr: string) => {
  const val1 = object1[attr];
  const val2 = object2[attr];
  if (val1 < val2) {
    return -1;
  } else if (val1 > val2) {
    return 1;
  } else {
    return 0;
  }
};
/**
 *
 * @param data
 * @param type  类型为TypeClass listModel合并到listTypeclass下  Model  不处理listTypeclass只需要listModel, visible 是根据type来控制row是否显示
 */
export const transformData = (
  data: BudgetReportProps,
  type: string
): RowProps[] => {
  let _data: RowProps[] = [];
  const make = data.listMake.map((val) => {
    val.visible = true;
    val.typeClass = val.make;
    val.level = 0;
    return val;
  });
  data.listModel = deepClone(
    modelRowEmpty(data.listModel).sort((object1, object2) =>
      compare(object1, object2, "model")
    )
  );
  data.listTypeclass = data.listTypeclass.sort((object1, object2) =>
    compare(object1, object2, "typeClass")
  );
  data.listTypeclass.forEach((item) => {
    item.level = 1;
    //根据type来处理数据
    if (type === "TypeClass") {
      item.visible = true;
    } else {
      item.visible = false;
    }
    _data.push(item);
    const children = data.listModel
      .filter((row) => row.typeClass == item.typeClass)
      .map((row) => {
        row.visible = true;
        row.level = 2;
        //根据type来处理数据
        if (type === "TypeClass") {
          row.typeclass = row.typeClass;
          row.typeClass = "";
        }
        return row;
      });
    _data = [..._data, ...children];
  });
  _data = [...make, ..._data];
  if (_data.length) transEmptyData(_data);
  return _data;
};
