
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  toRefs,
  watch,
  onBeforeUnmount,
  nextTick,
} from "vue";
import { TreeSelect } from "ant-design-vue";
import GC from "@grapecity/spread-sheets";
import moment from "moment";
import {
  initSpread,
  transformData,
  bindDataTable,
  setCollapsed,
  setHeader,
  initOutlineColumn,
} from "./utils";
import { RowProps, Header } from "./types";
import { getBudgetReportData, getVersion } from "@/API/budget";
import {
  getSubscribedBu,
  getBrand,
  getProgramStatus,
  getVehicleMake,
  scenrioList,
  findBuVehicleList,
} from "@/API/approve";
import useFetch from "@/hooks/useFetch";
import {
  BrandItem,
  BuItem,
  ProgramStatusItem,
} from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { ScenarioResultProps } from "./types";
import useFindBu from "@/hooks/Reporting/useFindBu";
import useBuMakeTP, { BuOptionProps } from "@/hooks/Reporting/useBuMakeTP";
import { useStore } from "vuex";
import { debounce } from "lodash-es";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

import { useRouter } from "vue-router";
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  disabled?: boolean;
  children?: TreeDataItem[];
}
export interface QueryParams {
  brand: string[];
  bu: string[];
  check: boolean;
  kufri: string;
  make: string;
  reportView: string;
  rtOrWs: string;
  yearQm: string;
  submitStatus: string[];
  activeStatus: string;
  planningVersion: number | null;
  year: string;
  qm: string[];
}

const qmOptions: TreeDataItem[] = [
  {
    title: "Q1",
    value: "Q1",
    key: "Q1",
    children: [
      {
        title: "01",
        value: "01",
        key: "01",
      },
      {
        title: "02",
        value: "02",
        key: "02",
      },
      {
        title: "03",
        value: "03",
        key: "03",
      },
    ],
  },
  {
    title: "Q2",
    value: "Q2",
    key: "Q2",
    children: [
      {
        title: "04",
        value: "04",
        key: "04",
      },
      {
        title: "05",
        value: "05",
        key: "05",
      },
      {
        title: "06",
        value: "06",
        key: "06",
      },
    ],
  },
  {
    title: "Q3",
    value: "Q3",
    key: "Q3",
    children: [
      {
        title: "07",
        value: "07",
        key: "07",
      },
      {
        title: "08",
        value: "08",
        key: "08",
      },
      {
        title: "09",
        value: "09",
        key: "09",
      },
    ],
  },
  {
    title: "Q4",
    value: "Q4",
    key: "Q4",
    children: [
      {
        title: "10",
        value: "10",
        key: "10",
      },
      {
        title: "11",
        value: "11",
        key: "11",
      },
      {
        title: "12",
        value: "12",
        key: "12",
      },
    ],
  },
];

const header: Header[][] = [
  [
    {
      name: "",
    },
    {
      name: "",
    },
    {
      name: "MTD",
    },
    {
      name: "MTD",
    },
    {
      name: "MTD",
    },
    {
      name: "MTD",
    },
    {
      name: "YTD",
    },
    {
      name: "YTD",
    },
    {
      name: "YTD",
    },
    {
      name: "YTD",
    },
  ],
  [
    {
      name: "Type Class",
    },
    {
      name: "Model",
    },
    {
      name: "%AVG.SI",
    },
    {
      name: "%Guideline",
    },
    {
      name: "%Deviation",
    },
    {
      name: "Deviation Absolut Amount",
    },
    {
      name: "%AVG.SI",
    },
    {
      name: "%Guideline",
    },
    {
      name: "%Deviation",
    },
    {
      name: "Deviation Absolut Amount",
    },
  ],
];

export default defineComponent({
  name: "budgetReport",
  setup() {
    const { state } = useStore();
    const router = useRouter();
    const getDefaultQm = (): string => {
      return "Q" + moment().add("month", 1).quarter();
    };
    const { buList, findBu, buIdMap } = useFindBu();
    const queryParams = reactive<QueryParams>({
      brand: [],
      bu: [],
      check: false,
      kufri: "",
      make: "",
      reportView: "TypeClass",
      rtOrWs: "RT",
      yearQm: "",
      submitStatus: [],
      activeStatus: "Active",
      planningVersion: null,
      year: moment().add("month", 1).format("YYYY"),
      qm: [getDefaultQm()],
    });

    const openYear = ref<boolean>(false);

    const {
      handleFocus,
      makeOptions,
      brandOptions,
      typeClassOptions,
      modelOptions,
      resetObj,
    } = useBuMakeTP<QueryParams>(queryParams);
    queryParams.bu = [findBu()];

    // sheet当前表格的初始化
    let workbook: GC.Spread.Sheets.Workbook | null;

    let worksheet: GC.Spread.Sheets.Worksheet | null;

    const reportViewOptions = ref<{ name: string; value: string }[]>([
      {
        name: "Type Class",
        value: "TypeClass",
      },
      {
        name: "Model",
        value: "Model",
      },
    ]);

    const rtWsOptions = ref<{ name: string; value: string }[]>([
      {
        name: "RT",
        value: "RT",
      },
      {
        name: "WS",
        value: "WS",
      },
    ]);

    //programStatus options
    const { data: programStatusOptions } = useFetch<ProgramStatusItem[]>(
      { type: "guideline" },
      [],
      getProgramStatus
    );
    const { data: scenarioResult } = useFetch<ScenarioResultProps[]>(
      { activeStatus: true },
      [],
      scenrioList
    );
    const reportData = ref<RowProps[]>([]);

    const handlerOpenChange = (status: any): void => {
      if (status) {
        openYear.value = true;
      }
    };

    const handlerPanelChange = (value: string): void => {
      const time = moment(value).format("YYYY");
      queryParams.year = time as string;
      openYear.value = false;
    };

    const ssHotTopInit = (data: RowProps[], body: QueryParams): void => {
      const spread = new GC.Spread.Sheets.Workbook(
        document.getElementById("report") as HTMLElement
      );
      const sheet = spread.getActiveSheet();
      worksheet = sheet;
      workbook = spread;
      initSpread(spread, sheet, header, data, router, body);
      //是否分组列
      if (data.length > 0 && queryParams.reportView === "TypeClass") {
        initOutlineColumn(sheet);
      }
      //根据reportView来判断是否折叠
      if (
        queryParams.reportView === "TypeClass" &&
        queryParams.check === false
      ) {
        setCollapsed(worksheet, data, !queryParams.check);
      }
      // 默认展开到typeclass
      if (queryParams.reportView === "TypeClass") {
        setCollapsed(worksheet, data, false, 0);
      }
    };

    //因为分组列显示和普通显示切换有问题所以每次都是销毁后重新创建
    const initData = (): void => {
      //year 有值并且qm没有值，则拼接全年的日期
      const _year =
        queryParams.year && queryParams.qm.length == 0
          ? ["Q1", "Q2", "Q3", "Q4"]
              .map((item) => `${queryParams.year}.${item}`)
              .join(",")
          : queryParams.qm
              .map((item) => `${queryParams.year}.${item}`)
              .join(",");
      const body = Object.assign({}, queryParams, {
        yearQm: _year,
        bu: queryParams.bu,
      });
      if (queryParams.planningVersion) {
        body.planningVersion = queryParams.planningVersion;
      }
      getBudgetReportData(body).then((res) => {
        const data = transformData(res, queryParams.reportView);
        Object.assign(reportData.value, data);
        workbook && workbook.destroy();
        workbook = null;
        worksheet = null;
        ssHotTopInit(data, body);
      });
    };
    //根据bu获取version最新版本
    const fetchLastVersion = (make: string) => {
      getVersion(make).then((data) => {
        queryParams.kufri = data;
        initData();
      });
    };

    const handlerResetClick = () => {
      Object.assign(queryParams, {
        brand: [],
        bu: [],
        check: true,
        // kufri: '',
        // make: '',
        reportView: "TypeClass",
        rtOrWs: "RT",
        yearQm: "",
        submitStatus: [],
        activeStatus: "Active",
      });
      queryParams.year = moment().add("month", 1).format("YYYY");
      queryParams.qm = [getDefaultQm()];
      initData();
    };

    const initVehicleMake = () => {
      const params = {
        target: "make",
        buId: buIdMap[queryParams.bu[0]],
        brand: queryParams.brand,
      };
      return findBuVehicleList(params).then((res) => {
        makeOptions.value = res;
        if (!res.some((item: any) => item.name === queryParams.make)) {
          Object.assign(queryParams, { make: makeOptions.value[0].name });
        }
      });
    };

    const changeMake = (bu: string) => {
      const params = {
        target: "make",
        buId: buIdMap[bu],
        brand: queryParams.brand,
      };
      return findBuVehicleList(params).then((res) => {
        makeOptions.value = res;
        if (!res.some((item: any) => item.name === queryParams.make)) {
          Object.assign(queryParams, { make: makeOptions.value[0].name });
        }
      });
    };

    const judgeBu = (bus: string[]) => {
      if (bus.length === 1) {
        return bus[0];
      } else {
        if (bus.includes("VAN")) {
          const vanIndex = bus.findIndex((bu: string) => bu === "VAN");
          return vanIndex !== 0 ? bus[0] : bus[1];
        } else {
          return bus[0];
        }
      }
    };

    const getMakeByBu = (bus: string[]) => {
      const bu = judgeBu(bus);
      changeMake(bu);
    };

    const debouncedSearch = debounce((value) => getMakeByBu(value), 1500);

    watch(
      () => queryParams.bu,
      (value) => {
        if (value.includes("AMG")) {
          queryParams.reportView = "Model";
        }
        debouncedSearch(value);
      }
    );

    watch(
      () => queryParams.reportView,
      (value) => {
        if (value === "Model") {
          queryParams.check = false;
        }
      }
    );

    //处理侧边栏变化后刷新spread js
    watch(
      () => state.sideNavCollapsed,
      (value) => {
        nextTick(() => {
          workbook && workbook.refresh();
        });
      }
    );
    watch(
      () => queryParams.make,
      (value) => {
        if (value) {
          fetchLastVersion(value);
        }
      }
    );

    onMounted(() => {
      initVehicleMake();
    });
    onBeforeUnmount(() => {
      workbook && workbook.destroy();
    });
    return {
      handlerResetClick,
      ...toRefs(queryParams),
      reportData,

      // options
      reportViewOptions,
      rtWsOptions,
      makeOptions,
      buList,
      brandOptions,
      programStatusOptions,
      scenarioResult,
      qmOptions,
      SHOW_PARENT,

      openYear,
      handlerOpenChange,
      handlerPanelChange,

      initData,
      handleFocus,
    };
  },
});
